<template>
  <div>
    <div class="wrapper">
      <section v-if="!isDone" key="content" class="section-info section-info-sm" id="service-card">
        <div class="jcard-main jcard-dark">
          <div class="appointment-grid">
            <div class="jtitle jtitle-80">
              <!-- <h3 class="jtitle-icon"><i class="fad fa-calendar-alt"></i></h3> -->
              <h3>Make an Appointment</h3>
            </div>

            <div class="jcard-border">
              <small>SERVICE NAME</small>
              <h5>{{ service.name }}</h5>
            </div>

            <div class="jcard-border">
              <small>SELECT A MONTH</small>
              <!-- <Datepicker id="datepicker" v-model="appointmentDate" lang="en" /> -->
              <select class="input-select" v-model="appointmentMonth">
                <option value="0">January</option>
                <option value="1">February</option>
                <option value="2">March</option>
                <option value="3">April</option>
                <option value="4">May</option>
                <option value="5">June</option>
                <option value="6">July</option>
                <option value="7">August</option>
                <option value="8">September</option>
                <option value="9">October</option>
                <option value="10">November</option>
                <option value="11">December</option>
              </select>
            </div>
            <div class="jcard-border">
              <small>SELECT A YEAR</small>
              <select class="input-select" v-model="appointmentYear">
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
              </select>
            </div>

            <div class="jcard-border">
              <small>PRICE</small>
              <h5>{{ service.price }}€</h5>
            </div>

            <div></div>
          </div>

          <div class="text-center mt-3 mb-3">
            <div class="jbtn jbtn-white" @click="buyService">
              <span v-if="isLoading" key="spinner"><i class="fad fa-spinner-third fa-spin"></i></span>
              <span v-else key="button"><i class="fal fa-check"></i> BUY</span>
            </div>
          </div>
        </div>
      </section>

      <!-- success -->
      <section v-if="isDone" key="success" class="section section-dark jpadding jpadding-25 section-unfixed">
        <div class="appointment-grid">
          <div class="jtitle text-center mb-5">
            <h3><i class="fad fa-check text-gold fa-3x"></i></h3>
            <h3>Appointment booked.</h3>
          </div>
          <div>
            <p>
              Thank you for booking your service appointment. The schedule will have to be confirmed manually
              by our Team.
            </p>
            <p>
              We will send you a confirmation per email shortly. You can also consult your appoitments in the
              Owners Club to stay up to date.
            </p>
          </div>
        </div>

        <div class="mt-5 text-center">
          <router-link :to="{ name: 'AppointmentsList' }" class="jbtn-watch-link text-gold">
            <i class="fal fa-clock"></i> MY APPOINTMENTS
          </router-link>
        </div>
      </section>
    </div>

    <!-- INFO MODAL -->
    <b-modal :hide-header="true" :hide-footer="true" id="info-modal" ref="info-modal" centered size="lg">
      <!-- Modal content-->
      <div class="jmodal-content">
        <!-- top -->
        <div class="jclose-float" @click="$bvModal.hide('info-modal')">
          <!-- <span aria-hidden="true">&times;</span> -->
          <i class="fal fa-times "></i>
        </div>
        <div class="jmodal-header">
          <!-- logo -->
          <a class="nav-brand" href="/">
            <img src="@/assets/logo/duke-logo-white.png" alt="" />
          </a>
        </div>

        <!-- main -->
        <div class="jmodal-main duke-style">
          <!-- title big -->
          <div class="jmodal-title">
            <h1 id="jmodal-icon"><i class="fal fa-exclamation-circle"></i></h1>
            <h1>Delivery info</h1>
            <!-- <h1>PLEASE READ THE INFORMATION BELOW</h1> -->
          </div>

          <p class="info-modal-content">
            <small
              >It seems that our Online Shop isn't able to deliver the Watch to your Country.</small
            >
          </p>
          <p class="info-modal-details text-center">
            <small>Please change your delivery address to one of the following countries:</small>
          </p>
          <p class="info-modal-details"></p>

          <div class="countries-grid">
            <p class="info-modal-details">
              <country-flag country="bh" size="small" /> <small>Bahrain</small>
            </p>
            <p class="info-modal-details"><country-flag country="ca" size="small" /> <small>Canada</small></p>
            <p class="info-modal-details">
              <country-flag country="eu" size="small" /> <small>European Union</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="hk" size="small" /> <small>Hong Kong</small>
            </p>
            <p class="info-modal-details"><country-flag country="in" size="small" /> <small>India</small></p>
            <p class="info-modal-details"><country-flag country="jp" size="small" /> <small>Japan</small></p>
            <p class="info-modal-details"><country-flag country="kw" size="small" /> <small>Kuwait</small></p>
            <p class="info-modal-details"><country-flag country="mo" size="small" /> <small>Macao</small></p>
            <p class="info-modal-details"><country-flag country="mx" size="small" /> <small>Mexico</small></p>
            <p class="info-modal-details"><country-flag country="om" size="small" /> <small>Oman</small></p>
            <p class="info-modal-details"><country-flag country="qa" size="small" /> <small>Qatar</small></p>
            <p class="info-modal-details">
              <country-flag country="sa" size="small" /> <small>Saudi Arabia</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="sg" size="small" /> <small>Singapore</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="ch" size="small" /> <small>Switzerland</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="ae" size="small" /> <small>United Arab Emirates</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="gb" size="small" /> <small>United Kingdom</small>
            </p>
            <p class="info-modal-details">
              <country-flag country="us" size="small" /> <small>United States</small>
            </p>
          </div>

          <div class="jbtn-container-center mt-5">
            <router-link
              :is="!profileRouteName ? 'span' : 'router-link'"
              :to="{ name: getProfileRouteName() }"
              class="jbtn jbtn-white jbtn-long"
              ><i class="fal fa-truck"></i> Edit delivery address</router-link
            >
          </div>

          <p class="info-modal-details text-center">
            <small>Or be contacted by DUKE to add your country, by email, by phone or by Whatsapp.</small>
          </p>

          <div class="jbtn-container-center mt-4">
            <router-link
              :to="{ name: 'Contact' }"
              class="jbtn jbtn-dark jbtn-long"
              ><i class="fal fa-comment"></i> Request contact</router-link
            >
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import C from "@/const";
// import "vue-datepicker-ui/lib/vuedatepickerui.css";
// import VueDatepickerUi from "vue-datepicker-ui";
import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
const db = firebase.db;
const auth = firebase.auth;
const serverTimestamp = firebase.serverTimestamp;

export default {
  name: "Appointment",
  components: {
    // Datepicker: VueDatepickerUi
  },
  data() {
    return {
      isLoading: false,
      isDone: false,
      
      profileRouteName: null,
      appointmentDate: null,
      appointmentMonth: null,
      appointmentYear: null,
      userId: "",
      watchId: null,
      serviceId: null
    };
  },
  methods: {
    getWatch: function(watchId) {
      //load watch
      var watchRef = db.collection(C.COLLECTION.WATCHES).doc(watchId);
      watchRef.get().then(doc => {
        //add id to doc
        const docdata = doc.data();
        docdata.id = doc.id;
        //set watch doc in store
        this.$store.commit("setWatch", docdata);
      });
    },
    getService: function(serviceId) {
      //load service
      db.collection(C.COLLECTION.SERVICE_CATALOG)
        .doc(serviceId)
        .get()
        .then(doc => {
          //add id to doc
          const docdata = doc.data();
          docdata.id = doc.id;
          //set service doc in store
          this.$store.commit("setService", docdata);
        });
    },
    getUser: function() {
      //load user with listener to always be up to date
      db.collection(C.COLLECTION.USERS)
        .doc(auth.currentUser.uid)
        .onSnapshot(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.$store.commit("setUser", docdata);
        });
    },

    async buyService() {
      if (!this.appointmentMonth || !this.appointmentYear) {
        toast.error("Please select a date");
        return;
      }
      if (this.isLoading || !this.serviceId) {
        return;
      }
      this.isLoading = true;

      //@deprecated: always use luxembourgish tax for services
      // check if we have country code
      // if (!this.user || !this.user.delivery || !this.user.delivery.countryCode) {
      //   this.openInfoDialog();
      //   this.isLoading = false;
      //   return;
      // }

      const countryCode = "LU";
      var taxRef = db
        .collection(C.COLLECTION.PRODUCTS)
        .doc(C.COLLECTION.TAX_RATES)
        .collection(C.COLLECTION.TAX_RATES)
        .where("country", "==", countryCode);
      
      //@deprecated: always use luxembourgish tax for services
      // check if we have state code if needed
      // if (countryCode == "CA" || countryCode == "US") {
      //   const stateCode = this.user.delivery.stateCode;
      //   if (!stateCode) {
      //     this.openInfoDialog();
      //     this.isLoading = false;
      //     return;
      //   }

      //   taxRef = taxRef.where("state", "==", stateCode);
      // }

      var taxRateQuerySnap;
      try {
        taxRateQuerySnap = await taxRef.get();
        if (!taxRateQuerySnap || taxRateQuerySnap.empty) {
          toast.error("Could not apply tax, check your internet connection");
          // this.openInfoDialog();
          this.isLoading = false;
          return;
        }
      } catch (error) {
        this.isLoading = false;
        toast.error(error);
        return;
      }

      var taxRateId = taxRateQuerySnap.docs[0].id;
      var taxRatePercent = taxRateQuerySnap.docs[0].data().percentage;
      // if there are mor rates, make sure to have the most recent one
      if (taxRateQuerySnap.docs > 1) {
        var created = 0;
        taxRateQuerySnap.docs.forEach(taxRateDoc => {
          if (created < taxRateDoc.created) {
            const tax = taxRateDoc.data();
            taxRateId = taxRateDoc.id;
            created = tax.created;
            taxRatePercent = tax.percentage;
          }
        });
      }

      const productID = C.PRODUCT_PREFIX + this.serviceId;

      // get the stripe prices for the selected service
      // there should only be one active price
      // => TODO: use firebase functions to set ID of most recent (onCreate) prize in watchCatalog doc instead - also try-catch
      var querySnapshot;
      try {
        querySnapshot = await db
          .collection(C.COLLECTION.PRODUCTS)
          .doc(productID)
          .collection(C.COLLECTION.PRICES)
          .where("active", "==", true)
          .limit(1)
          .get();
      } catch (error) {
        this.isLoading = false;
        toast.error(error);
        return;
      }

      if (!querySnapshot || querySnapshot.empty) {
        this.isLoading = false;
        toast.error("Service not available");
        return;
      }

      // TODO: stripe does only apply the right tax rate if it is set in tax_rates array of the price item in DB => always need to be up to date
      // resp. we need to build the price item here with tax rate for session => BUT there are probably restrictions to that, otherwise, user could always build their own prie and buy stuff for 1€
      // also always need to check the right tax rate that applies to the buying user and make sure the price supports it, otherise deny purchase
      const priceid = querySnapshot.docs[0].id;
      const selectedPrice = {
        price: priceid,
        quantity: 1,
        tax_rates: [taxRateId]
      };

      if (!this.isLoading) {
        return;
      }

      //prepare date
      this.appointmentDate = new Date();
      this.appointmentDate.setFullYear(this.appointmentYear, this.appointmentMonth);
      // prepare appointment
      const newAppointmentRef = db.collection(C.COLLECTION.APPOINTMENTS).doc();
      const appointmentDoc = C.APPOINTMENT;
      appointmentDoc.date = this.appointmentDate;
      appointmentDoc.userId = this.userId;
      appointmentDoc.watchId = this.watchId;
      appointmentDoc.serviceId = this.serviceId;
      appointmentDoc.serviceName = this.service.name;
      appointmentDoc.servicePrice = this.service.price;
      try {
        await newAppointmentRef.set(appointmentDoc);
      } catch (error) {
        console.log(error);
        return;
      }

      // You will be redirected to Stripe's secure checkout page
      const checkoutSession = {
        mode: "payment",
        payment_method_types: ["card"],
        automatic_tax: false,
        // price: priceid,
        tax_id_collection: false,
        collect_shipping_address: true,
        allow_promotion_codes: true,
        line_items: [selectedPrice],
        // NOT NEEDED/WORKING I THINK => stripe should sync automatically if everything is properly set up
        // customer: this.user.stripeId,
        // billing_address_collection: "required",
        // customer_details: {
        //   "address": this.user.addr,
        //   "email": this.user.email,
        //   "name": this.user.firstName + " " + this.user.lastName,
        //   "phone": this.user.phone,
        //   "tax_exempt": "none",
        //   "tax_ids": null
        // },
        phone_number_collection: {
          enabled: false
        },
        success_url: C.CLUB_URL + "owner/redirection/" + newAppointmentRef.id,
        // success_url: "http://localhost:8080/owner/appointment/success",
        cancel_url: window.location.origin,
        // provide details so backend can create the appointment
        metadata: {
          productType: C.PRODUCT_TYPE.SERVICE,
          watchId: this.watchId,
          serviceId: this.serviceId,
          appointmentId: newAppointmentRef.id,
          taxPercent: taxRatePercent,
        }
      };

      // Create checkout session document in database
      const docRef = await db
        .collection(C.COLLECTION.USERS)
        .doc(auth.currentUser.uid)
        .collection(C.COLLECTION.CHECKOUT_SESSION)
        .add(checkoutSession);

      console.log("checkoutSession created for user:" + this.userId);

      // Wait for the CheckoutSession url to get attached by the extension
      docRef.onSnapshot(snap => {
        const { error, url } = snap.data();
        if (error) {
          // Show an error to your customer and then inspect your function logs.
          alert(`An error occured: ${error.message}`);
        }
        if (url) {
          window.location.assign(url);
        }
      });
    },
    openInfoDialog() {
      this.$refs["info-modal"].show();
    },
    // @deprecated
    createAppointment() {
      if (!this.appointmentMonth || !this.appointmentYear) {
        toast.error("Please select a date");
        return;
      }
      if (this.isLoading) {
        return;
      }
      //prepare date
      this.appointmentDate = new Date();
      this.appointmentDate.setFullYear(this.appointmentYear, this.appointmentMonth);
      this.isLoading = true;
      db.collection(C.COLLECTION.APPOINTMENTS)
        .doc()
        .set({
          createdOn: serverTimestamp,
          userId: this.userId,
          watchId: this.watch.id,
          serviceId: this.service.id,
          date: this.appointmentDate,
          modelId: this.watchModleId,
          status: 0
        })
        .then(() => {
          this.isDone = true;
          this.isLoading = false;
          toast.success("New service appointment created.");
        })
        .catch(error => {
          toast.error("Couldn't create appointment. " + error.message);
        });
    },
    getProfileRouteName() {
      const type = this.$store.getters.getUserTypeString;
      if (type) {
        this.profileRouteName = "Profile" + type;
      } else {
        this.handleClaimsError();
      }

      return this.profileRouteName;
    },
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    watch: {
      get() {
        return this.$store.getters.getWatch;
      }
    },
    service: {
      get() {
        return this.$store.getters.getService;
      }
    }
  },
  created() {
    //get watch id from params
    if (this.$route.params.watchId != null && this.$route.params.watchId != "") {
      this.watchId = this.$route.params.watchId;
    }
    // check if watch needs to be loaded
    if (!this.watch || this.watch.id != this.watchId) {
      this.getWatch(this.watchId);
    }

    //get service id from params
    if (this.$route.params.serviceId != null && this.$route.params.serviceId != "") {
      this.serviceId = this.$route.params.serviceId;
    }
    // check if sercice needs to be loaded
    if (!this.service || this.service.id != this.serviceId) {
      this.getService(this.serviceId);
    }

    // check if user needs to be loaded
    this.userId = auth.currentUser.uid;
    if (this.user && this.user.id == this.userId) {
      return;
    }
    //load user
    this.getUser();
  }
};
</script>

<style scoped>
#datepicker {
  --v-calendar-picker-color: #1b1e23;
  --v-calendar-input-bg-color: #232323;
  --v-calendar-select-bg-color: #1b1e23;
  --v-calendar-border-color: #fff;
  --v-calendar-triangle-color: #1b1e23;
  --v-calendar-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.2);
  --v-calendar-top-shadow: 3px -14px 30px 0px rgba(0, 0, 0, 0.2);
  --v-calendar-text-color: #b8b8b9;
  --v-calendar-action-color: #b8b8b9;
  --v-calendar-text-disabled-color: #b8b8b97c;
  --v-calendar-view-button-color: #b8b8b9;
  --v-calendar-datepicker-icon-color: #b8b8b9;
  --v-calendar-datepicker-icon-size: 1.1rem;
  --v-calendar-active-bg-color: #f7cd00;
  --v-calendar-active-text-color: #fff;
  --v-calendar-range-bg-color: #282c34;
  --v-calendar-view-button-font-weight: 00;
  --v-calendar-view-button-font-size: 1.1rem;
  --v-calendar-range-text-color: #ddd;
  --v-calendar-range-radius: 100%;
  --v-calendar-day-hover-bg-color: #fff;
  --v-calendar-day-width: 45px;
  --v-calendar-day-height: 45px;
  --v-calendar-day-font-size: 0.8rem;
  --v-calendar-day-font-weight: 500;
  --v-calendar-input-border: 1px solid #383e48;
  --v-calendar-input-text-color: #fff;
  --v-calendar-input-font-size: 0.9rem;
  --v-calendar-input-font-weight: 500;
  --v-calendar-content-radius: 6px;
  --v-calendar-day-name-font-size: 0.9rem;
  --v-calendar-day-name-font-weight: 600;
  --v-calendar-day-name-color: #fff;
  --v-calendar-year-font-size: 1.1rem;
  --v-calendar-year-color: #b8b8b9;
  --v-calendar-year-font-weight: 500;
  --v-calendar-input-bg-disable-color: rgb(245, 245, 245);
  --v-calendar-input-text-disable-color: #b8b8b9;
  --v-calendar-year-disabled-color: #b8b8b9;
  --v-calendar-year-disabled-bg-color: transparent;
  --v-calendar-year-padding: 10px;
  --v-calendar-year-border: none;
  --v-calendar-year-border-radius: none;
  --v-calendar-month-font-size: 1.1rem;
  --v-calendar-month-color: #b8b8b9;
  --v-calendar-month-font-weight: 500;
  --v-calendar-month-disabled-color: #b8b8b9;
  --v-calendar-month-disabled-bg-color: transparent;
  --v-calendar-month-padding: 8px;
  --v-calendar-month-border: none;
  --v-calendar-month-border-radius: none;
}
</style>
